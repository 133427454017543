////////////////////////////// GLOBAL FUNCTIONS ////////////////////////////////////

/**
 *
 * @param {date} date - ISO date format 2020-06-03T19:36:50.018Z
 * @returns {string} - date as string
 */
export function formatDateDDMMYYY(date){
    date = new Date(date)
    let day = date.getDate() 
    let month = date.getMonth() 
    month = month + 1
    if(month<10){
        month = "0" + month
    }
    let year = date.getFullYear()
    let formatStartDate = day + "/" + month + "/" + year
    return formatStartDate
}

/**
 *
 * @param {date} date - ISO date format 2020-06-03T19:36:50.018Z
 * @returns {string} - time as string
 */
 export function formatHoursHHhMM(date){
    date = new Date(date)
    let minutes = date.getMinutes();
    if (date.getMinutes() < 10) {minutes = "0" + date.getMinutes();}
    let time = date.getHours() +"h" + minutes;
    return time
}

/**
 *
 * @param {number} fillingRatio - fillingRation to be compared
 * @param {array} thresholdArray - array containing all thresholds
 * @param {array} colorArray - array containing all colors according to thresholdArray
 * @param {int} remainingSpots - number of available slots
 * @returns {string} - time as string
 */
export function findColorBasedOnRatio(fillingRatio, thresholdArray, colorArray, remainingSpots){
    let color
    // Adding red icon url
    if(fillingRatio >= thresholdArray[2] || remainingSpots <= 2){
        color = colorArray[2];
        // Adding Orange icon url
    } else if (fillingRatio >= thresholdArray[1] && fillingRatio < thresholdArray[2]){
        color = colorArray[1]
        // Adding Green icon url
    } else {
        color = colorArray[0]
    }
    return color
}

/**
 *
 * @param {String} customer
 */
export function storeCustomerInLS(customer){
    localStorage.setItem("customer", customer)  
}

/**
 *
 * @return {String} customer
 */
export function getCustomerInLS(customer){
    return localStorage.getItem("customer", customer)  
}


/**
 *
 * @param {Object} location - lat lng object corresponding to user position
 */
export function storeUserLocationInLS(location){
    localStorage.setItem("lastKnownLocation", JSON.stringify(location))  
}


/**
 * It takes an array and a string as arguments and returns true if the string contains any of the
 * elements in the array
 * @param array - an array of strings
 * @param substring - the string that you want to check if it contains a slot type
 * @returns a boolean value.
 */
export function findStringInArray(array, substring){
    let resp = false
    array.find(element => {
        if (substring.includes(element)) {
          resp = true;
        }
    });
    return resp
}