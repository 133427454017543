import React from 'react'

//UTILS
import logo from '../images/logo.png'

function AskGetLocation() {

    function askForGeoloc(){
        
        
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("user has allowed geolocation");
            document.location.reload(true);
            
        }, function() {
            console.log("user has refused geolocation");
            document.location.reload(true);
        });
    }

    function ignoreGeoloc () {
        console.log("user has refused geolocation");
        document.location.reload(true);
    }

    return (
        <div className="flex-center-column" style={{height: "100vh", textAlign: "center", padding: "20px", color: "var(--dark-grey)"}}>
            <img src={logo} alt="Logo" style={{width: "30vh", marginBottom: "20px"}}/> 
            <p>Hey ! Cocoparks utilise la <strong>localisation</strong> de votre smartphone afin de vous aider dans votre recherche de stationnement. </p>
            <p>Activez la localisation pour gagner du temps et optimiser vos dépenses !</p>  
            <button className="green-action-button" style={{marginTop: "10px"}}
                onClick={()=> askForGeoloc()}
            >
                <span className="green-action-button-text">Activer</span>
            </button>
            
            <button className="green-button-text" style={{marginTop: "10px"}}
                onClick={()=> ignoreGeoloc()}
            >
                <span>Je ne souhaite pas utiliser la localistion</span>
            </button>
        </div>
    )
}

export default AskGetLocation
