import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX ACTIONS
import { toggleAskGeolocationPanel } from '../detailsPanel/state/displayAskGeolocationPanelAction'
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction'

//STYLE
import * as IoIcon from 'react-icons/io5';

function AskGeolocationPanel(props) {
    const dispatch = useDispatch()
    
    function deactivateAskGeolocationPanel(){
        dispatch(toggleAskGeolocationPanel({ value: false }))
        dispatch(showDetailPanelContainer())
    }

    function askForGeoloc(){
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("user has allowed geolocation");
            document.location.reload(true);
        }, function() {
            console.log("user has refused geolocation");
            document.location.reload(true);
        });
    }

    return (
        <>
            <div className='specific-detail-panel-container flex-center-column'>
                <div onClick={()=>{deactivateAskGeolocationPanel()}}>
                    <IoIcon.IoClose 
                        style={{color: "var(--blue)", width: "20px", height: "20px"}}
                    />
                </div>

                <div className="flex-center-column" style={{width: "100%"}}>
                    <p className="black-title" style={{marginTop: "10px", textAlign: "center"}}>Pour vous orienter facilement dans le parking, veuillez activer la géolocalisation.</p>
                    <button className="green-action-button" style={{marginTop: "10px"}} onClick={()=> askForGeoloc()}>
                        <span className="green-action-button-text">J'active ma géolocalisation</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default AskGeolocationPanel
