import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadScript} from "@react-google-maps/api"
// import { io } from 'socket.io-client';
import socket from './socketio/Socket';


//COMPONENTS
import Map from './map/Map';
import EmptyMap from './map/EmptyMap';
import IntroductionSwiper from './cocoparksIntroduction/IntroductionSwiper';

//REDUX SELECTOR
import { userUidSelector } from './global/state/userUidSelector';

//REDUX ACTION
import { updateUserLocation } from './markers/state/userLocationAction';
import { toggleSparkedMode } from './global/state/sparkedModeAction';
import { showSparkedMarker } from './markers/state/sparkedMarkerAction';
import { addTtpInfo } from './sparkedMode/state/ttpInfoAction';
import { updateSpotScreenshot } from './sparkedMode/state/screenshotImageSpotAction';
import { addSparkedParkingData } from './sparkedMode/state/sparkedParkingDataAction';
import { toggleGetBackToRecentTorchAddress } from './itinerary/state/displayGetBackToRecentTorchAddressAction';
import { toggleUserResearchChoice } from './userIntroduction/state/displayUserResearchChoiceAction';
import { toggleInstallCocoparks } from './installation/state/displayInstallCocoparksAction';
import { updateSparkedCocoMarkerData } from './socketio/state/sparkedCocoMarkersDataAction';
import { addFreedSlotMarkerData } from './markers/state/freedSpotMarkerDataAction'
import { addDeferredPrompt } from './installation/state/deferredPromptAction'
import { updateCocoLoaderState } from './userIntroduction/state/cocoLoaderStateAction'

//UTILS
import * as global from './global/globalVariable'
import { storeUserLocationInLS } from './global/GlobalUtils';

const libraries = ["geometry", "places"];

function App() {

    const dispatch = useDispatch()

    const userUid = useSelector(userUidSelector)
    const config = useSelector((state) => state.config)
    
    const [loadingUserLocation, setLoadingUserLocation] = useState(true)
    const [displayCocoIntro, setDisplayCocoIntro] = useState(true)
    const [geolocationActive, setGeolocationActive] = useState(true)
    const [receivedUserLocation, setReceivedUserLocation] = useState(false)

    let cocoparksVersionNumber = global.cocoparksVersionNumber 

    //set user position from navigator to userLocation => onSuccess function from getCurrentPosition()
    async function getUserLocation(position){
        let location = config.geolocalisation_searchbar
            ? { lat : position.coords.latitude, lng : position.coords.longitude }
            : config.map_center
        await dispatch(updateUserLocation(location))
        dispatch(updateCocoLoaderState())
        storeUserLocationInLS(location)
        setLoadingUserLocation(false)
        setReceivedUserLocation(true)
    }


    //error function function from getCurrentPosition()
    async function onError(e){
        // if User denied Geolocation
        if (e.code === 1) {
            if (!config.geolocalisation_searchbar) {
                getUserLocation(config.map_center)
            }
        } else {
            setLoadingUserLocation(true)
            setGeolocationActive(false)
        }
    }


    /**
     * @returns {number} - Value of stopDisplayingIntroUserPath (localStorage variable located in workingData)
     */
    function getStopDisplayingIntroUserPathValue(){
        var workingData = JSON.parse(localStorage.getItem('workingData'));
        //If the value of stopDisplayingIntroUserPath is equal to 1, 
        //it means that the user has chosen not to display the message anymore or 
        //if there are address search parameters in the url 
        //then we return false to hide the <UserResearchChoice/> component.
        if(workingData.stopDisplayingIntroUserPath === 1 || new URLSearchParams(window.location.search).get("address") !== null){
            return false
        } else {
            return true
        }
    }

    useEffect(async () => {

        //setting user location to user last known position to display map faster because map needs a user location
        //user location will then be update as soon as getCurrentPosition returns the actual position of the user
        let lastKnowUserLocation = JSON.parse(localStorage.getItem('lastKnownLocation'))
        if(lastKnowUserLocation){
            await dispatch(updateUserLocation({
                lat : lastKnowUserLocation.lat,
                lng : lastKnowUserLocation.lng,
                lastKnowUserLocation: true
            }))
            setLoadingUserLocation(false)
        }

        if('serviceWorker' in navigator){
            /**
             * For install the PWA
             */
            //If the app is installed, put in localStorage the app is installed
            window.addEventListener('appinstalled', (event) =>
            {
                // Track event: The app was installed (banner or manual installation)
                // console.log("APP IS INSTALLED");
                localStorage.setItem('installGood', '1')
            });

            //to request the installation of the app, if the mobile isn't a iphone
            if(localStorage.getItem('installGood') == null){
                window.addEventListener('beforeinstallprompt', (event) =>
                {
                    // console.log("in before install prompt")
                    event.preventDefault()
                    dispatch(addDeferredPrompt(event))
                });
            }

            //to display installation message only if user didn't already install the app
            if(localStorage.getItem('installGood') == null){
                //to display installation message 20sec after the initialization of the app
                setTimeout(function(){
                        dispatch(toggleInstallCocoparks(true))
                    }, 20 * 1000)
            }
        }

        
        //to know if it's user's first connection
        let knownCoco = localStorage.getItem('knownCoco')
        
        //to display cocoparks introduction if it's user's first connection
        if(knownCoco){
            setDisplayCocoIntro(false)
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getUserLocation, onError);
            } else {
                alert('Geolocation is not supported by this device');
            }
        }

        let cocoparksVersionLS = localStorage.getItem('cocoparksVersion')
        
        //to store cocoparksVersionNumber in LS if there isn't any
        if(cocoparksVersionLS === null){
            localStorage.clear()
            //to add version number in ls
            localStorage.setItem('cocoparksVersion', cocoparksVersionNumber)
            //to not display intro 
            if(knownCoco){
                localStorage.setItem('knownCoco', "1")
            }
        } else if(cocoparksVersionLS === cocoparksVersionNumber){
            //same version than the actual, do nothing
            console.log("SAME VERSION THAN ACTUAL")
        } else {
            //to update ls version to the actual one
            console.log("EXISTING VERSION IN LS", cocoparksVersionLS)
            localStorage.setItem('cocoparksVersion', cocoparksVersionNumber)
            console.log("UPDATING VERSION TO", cocoparksVersionNumber)

        }

        socket.emit("new", "hello");
        socket.on('ttp info', data => {
            dispatch(updateSparkedCocoMarkerData([data]))
        });
        socket.on('freed spot', data => {
            dispatch(addFreedSlotMarkerData([data]))
        });
        socket.on('new', data => {
            dispatch(addFreedSlotMarkerData(data["ongoingFreedSpots"]))
            dispatch(updateSparkedCocoMarkerData(data["ongoingCocoTTPs"]))
        });

        //TO BE DELETE USED TO DISPLAY MAP ON SAFARI WHICH CAN'T ACCESS GEOLOC FROM HTTP
        // dispatch(updateUserLocation({
        //     lat : 48.8566,
        //     lng : 2.3522
        // }))
        // setLoadingUserLocation(false)
        
            
        return () =>  socket.disconnect();

    }, [dispatch])


    if(userUid !== null) {
        //when the app is initialize check in LS if the user is parked
        let lsuid = JSON.parse(localStorage.getItem(userUid));
        if(lsuid != null){
            if(lsuid.lastSparkedStatus){
                startSparkedMode(lsuid)
            }
        }
    }

    let workingData = JSON.parse(localStorage.getItem('workingData'))
    if(workingData != null){
        if(workingData.lastTorchData){
            //when the app is initialize check if there is a recent search address with the torch
            if(workingData.lastTorchData.alreadyDisplayed === 0){
                displayGetBackToRecentTorchAddressScreen();
            } else {
                let stopDisplayingValue = getStopDisplayingIntroUserPathValue()
                dispatch(toggleUserResearchChoice(stopDisplayingValue))
            }
        } else {
            let stopDisplayingValue = getStopDisplayingIntroUserPathValue()
            dispatch(toggleUserResearchChoice(stopDisplayingValue))
        }
    } else {
        dispatch(toggleUserResearchChoice(true))
    }

    //to switch the app to sparked mode (+ display sparker marker)
    function startSparkedMode(lsuid){
        let TTPinfo = {
            "position": lsuid.lastSparkedData.position,
            "ttp": lsuid.lastSparkedData.ttp,
            "start": lsuid.lastSparkedData.startTimestamp,
        };
        //to update the store sith the informations from LS
        //because the store is used to render informations
        dispatch(addTtpInfo(TTPinfo))
        let sparkedParkingData = {
            name: lsuid.lastSparkedData.pname,
            price: lsuid.lastSparkedData.ptarifstruct,
            location: lsuid.lastSparkedData.position
        }
        dispatch(addSparkedParkingData(sparkedParkingData))
        //displaying the correct sparkedDetailPanel depending on the user being parked onstreet or offstreet
        if(lsuid.lastSparkedData.ptype === "onstreet"){
            dispatch(toggleSparkedMode(true, "onstreet"))
        } else {
            dispatch(toggleSparkedMode(true, "offstreet"))
        }
        dispatch(showSparkedMarker(lsuid.lastSparkedData.position))
        //to store in redux the last screenshot of the slot taken by the user if there is one 
        //(getting this data from LS)
        if(lsuid.lastSparkedData.picture){
            dispatch(updateSpotScreenshot(lsuid.lastSparkedData.picture))
        }
    }


    /**
     * Display the getBackToRecentTorchAddressScreen if it has not already been displayed
     * And if it's been less than 3 hours since the last address was entered
     */
    async function displayGetBackToRecentTorchAddressScreen(){
        let workingData = JSON.parse(localStorage.getItem('workingData'));
        let lsTorchData = workingData.lastTorchData;
        if( ((Date.now()/ 3600000) - (lsTorchData.torchTime/3600000) < 3)){
            // If it's been less than 3 hours since the last address was entered
            dispatch(toggleGetBackToRecentTorchAddress({value: true, address: lsTorchData.torchAddressName}))
            workingData.lastTorchData.alreadyDisplayed = 1 // alreadyDisplayed = 1 to not show again the screen
            localStorage.setItem('workingData', JSON.stringify(workingData)); // update the localStorage
        }
    }

    if(displayCocoIntro){
        return(
            <IntroductionSwiper/>
        )
    }else {
        return (
                <div  style={{backgroundColor: "white", width: "100vw", height: "100vh"}}>
                    <div style={{width: "100%", height: "100vh"}} className="flex-center-column">
                            <LoadScript googleMapsApiKey="AIzaSyDxty_pKhTuEIuuw0-usX0iSmuzPLytwuo" id="script-loader" libraries={libraries}>
                                {/* {loadingUserLocation && config.geolocalisation_searchbar
                                    ? <EmptyMap geolocationActive={geolocationActive}/>
                                    : <Map zoom={config.zoom} receivedUserLocation={receivedUserLocation}/>
                                } */}
                                <Map zoom={config.zoom} receivedUserLocation={receivedUserLocation}/>
                            </LoadScript>
                    </div>
                </div>
        )
    }


}

export default App

