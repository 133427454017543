import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';

//COMPONENTS
import AskGetLocation from './AskGetLocation';

//REDUX SELECTORS
import { displayAskGetLocationSelector } from './state/displayAskGetLocationSelector'

//REDUX ACTIONS
import { toggleAskGetLocation } from './state/displayAskGetLocationAction';

//UTILS
import introData from './introductionTextContent.json'

// UTILS
import { getCustomerInLS } from '../global/GlobalUtils';

//STYLE
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './IntroductionSwiper.css'

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

function IntroductionSwiper() {
    const config = useSelector((state) => state.config);
    const customer = getCustomerInLS()
    const filteredIntroData = introData[customer] //.filter((value, index) => config[`onboarding_step_${index + 1}`] === true)
    const onboardingStatus = Array.from({ length: 4 }, (_, i) => config[`onboarding_step_${i + 1}`])
    const deactivateOnboardingAllSteps = onboardingStatus.every(value => value === false)

    const dispatch = useDispatch()

    const displayAskGetLocation = useSelector(displayAskGetLocationSelector)

    /**
     * adds a 1 in local storage to ensure Cocoparks recognizes user has already connected
     * if not found, then considers first connection and shows tutorial
     */
    function incrementLocalStorage() {
        localStorage.setItem('knownCoco', '1');
    }

    // to display component AskGetLocation
    function showAskGetLocation() {
        dispatch(toggleAskGetLocation(true))
    }

    // if (deactivateOnboardingAllSteps) {
    //     if (displayAskGetLocation) {
    //         if (config.geolocalisation_searchbar) {
    //             return <AskGetLocation />
    //         } else {
    //             // document.location.reload(true)
    //             return null
    //         }
    //     } else {
    //         incrementLocalStorage();
    //         showAskGetLocation();
    //         return null;
    //     }
    // }

    if (displayAskGetLocation) {
        if (config.geolocalisation_searchbar) {
            return <AskGetLocation />
        } else {
            document.location.reload(true)
        }
    } else {
        return (
            <div style={{ backgroundColor: "var(--light-green)", width: "100vw", height: "100vh" }}>
                <Swiper
                    pagination={{
                        "dynamicBullets": true,
                    }}
                    navigation={true}
                >
                    {filteredIntroData.map((item, key) => {
                        if (key === 0 && customer == 'amp') {
                            return (
                                <SwiperSlide key={key} style={{ height: "85vh" }}>
                                    <div className="intro-img- mx-2" style={{ marginBottom: "30%" }}>
                                        <img src={item.imagePath} alt="phone" />
                                    </div>
                                    <div className="introTop">
                                        <h2 className="title-intro">{item.title}</h2>
                                        <p className="content-intro">{item.content}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        } else if (key === filteredIntroData.length - 1) {
                            return (
                                <SwiperSlide key={key} style={{ height: "85vh" }}>
                                    <div className="introTop">
                                        <h2 className="title-intro">{item.title}</h2>
                                        <p className="content-intro">{item.content}</p>
                                    </div>
                                    <div className="intro-img">
                                        <img src={item.imagePath} alt="phone" />
                                    </div>
                                    <button className="green-action-button" style={{ marginBottom: "25px" }}
                                        onClick={() => { incrementLocalStorage(); showAskGetLocation() }}
                                    >
                                        <span className="green-action-button-text">C'est parti !</span>
                                    </button>
                                </SwiperSlide>
                            )
                        } else {
                            return (
                                <SwiperSlide key={key} style={{ height: "85vh" }}>
                                    <div className="introTop">
                                        <h2 className="title-intro">{item.title}</h2>
                                        <p className="content-intro">{item.content}</p>
                                    </div>
                                    <div className="intro-img">
                                        <img src={item.imagePath} alt="phone" />
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    })}
                </Swiper>
            </div>
        )
    }
}

export default IntroductionSwiper
